export interface IServicesData {
    name: string;
    list: Array<string>
}

export const ServicesData = [
    {
        name: 'Планирование',
        list: [
            'Проектировка помещений',
            'Детальная планировка',
            'Детальная рабочая документация',
            'Проект меблировки'
        ]
    },
    {
        name: 'Дизайн',
        list: [
            'Выбор материалов',
            'Концепция цветового и стилистического решения',
            'Мебельный дизайн',
            'Декоративные детали, аксессуары'
        ]
    },
    {
        name: 'Визуализация',
        list: [
            'Визуальные макеты 3D',
            'Детальная визуализация',
            'Цветовые коллажи, скетчи'
        ]
    },
    {
        name: 'Работа на строительной площадке',
        list: [
            'Тендер на проведение работ и передача заказа',
            'Предоставление и согласование сметной стоимости',
            'Авторский надзор',
            'Контроль качества'
        ]
    },
    {
        name: 'Закупка материалов',
        list: [
            'Получение наилучших условий при закупке',
            'Проверенное качество от профессионалов',
            'Персональное сопровождение при личном осуществлении закупок'
        ]
    }
];
