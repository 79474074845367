import React, {FC} from 'react';

import {SubHeading} from '../../../components';
import {ServicesData} from '../../../mockData/Services';
import styles from '../index.module.scss';
import S from '../index.style';
import {TServicesCard} from '..';

const DeckstopServicesCard: FC<TServicesCard> = (props) => {
    return (
        <S.Card
            style={{
                marginBottom: props.index === ServicesData.length - 1 ? '30px' : '0px'
            }}
        >
            <SubHeading whiteText>{props.name}</SubHeading>
            <S.BorderItem />
            {props.list.map((el) => (
                <S.ItemContainer key={el}>
                    <S.ListDot className={styles.dot} />
                    <S.TextMarker key={el}>{el}</S.TextMarker>
                </S.ItemContainer>
            ))}
        </S.Card>
    );
};
export default DeckstopServicesCard;
