import React, {memo} from 'react';
import {Grid} from '@nextui-org/react';

import {
    Border,
    Container,
    Heading,
    SubHeading,
    TextWrapper
} from '../../components';
import {IServicesData, ServicesData} from '../../mockData/Services';

import DeckstopServicesCard from './DecstopCard';
import styles from './index.module.scss';
import S from './index.style';
import MobileServicesCard from './MobileCard';

export interface TServicesCard extends IServicesData {
  index: number;
}

const Services = () => {
    return (
        <Container
            id="services"
            style={{
                backgroundColor: 'black'
            }}
        >
            <S.CoverBg>
                <S.Images
                    image={'/assets/main/5.webp'}
                    role="img"
                    aria-label="services background"
                />
            </S.CoverBg>
            <TextWrapper width={1200} style={{zIndex: 3}}>
                <Heading style={{color: 'white', marginTop: '30px'}}>Услуги</Heading>
                <Border />
                <SubHeading style={{marginBottom: '30px'}} whiteText>
          Спектр наших услуг охватывает весь комплекс планировочных работ,
                    <br></br> начиная с анализа индивидуальных потребностей клиента
                    <br></br> и заканчивая передачей готового помещения.
                </SubHeading>
                <Grid.Container gap={3}>
                    {ServicesData.map((item, index) => (
                        <Grid key={item.name} className={styles.gridWidth}>
                            <MobileServicesCard index={index} {...item} />
                            <DeckstopServicesCard index={index} {...item} />
                        </Grid>
                    ))}
                </Grid.Container>
            </TextWrapper>
        </Container>
    );
};

export default memo(Services);
