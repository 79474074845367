import React, {FC} from 'react';
import {Collapse} from '@nextui-org/react';

import {SubHeading} from '../../../components';
import {ServicesData} from '../../../mockData/Services';
import styles from '../index.module.scss';
import S from '../index.style';
import {TServicesCard} from '..';

const MobileServicesCard: FC<TServicesCard> = (props) => {
    return (
        <Collapse
            title={<SubHeading whiteText>{props.name}</SubHeading>}
            css={{
                paddingInline: '10px',
                marginBottom: props.index === ServicesData.length - 1 ? '30px' : '0px'
            }}
            className={styles.card}
        >
            <S.BorderItem />
            {props.list.map((el) => (
                <S.ItemContainer key={el}>
                    <S.ListDot style={{marginTop: '10px'}}/>
                    <S.TextMarker key={el}>{el}</S.TextMarker>
                </S.ItemContainer>
            ))}
        </Collapse>
    );
};

export default MobileServicesCard;
