import styled from 'styled-components';

import {colors} from '../../utils/colors';

const CoverBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Images = styled.div<{ image: string }>`
  transition: background-image 1s ease-in-out;
  background-image: url(${(props) => props.image});
  background-color: black;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0.7;
`;

const TextMarker = styled.span`
  text-align: left;
  color: ${colors.gray400};
`;

const Card = styled.div`
  padding-block: 20px;
  padding-inline: 15px;
  border-radius: 10px;
  background: #212121;
  box-shadow: 15px 15px 30px rgb(25, 25, 25), 0px 0px 10px rgb(60, 60, 60);
  p {
    text-align: left;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const BorderItem = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 10px;
  margin-block: '15px';
  background-color: ${colors.primary};
  @media screen and (max-width: 600px) {
    margin-block: 0px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-block: 7px;
`;

const ListDot = styled.div`
  background-color: ${colors.primary};
  padding: 3px;
  border-radius: 10px;
  margin-right: 10px;
`;

export default {
    CoverBg,
    Images,
    TextMarker,
    Card,
    BorderItem,
    ItemContainer,
    ListDot
};
